import type { ComponentType } from "react"
export function consentGranted(Component): ComponentType {
    return (props) => {
        const onClick = () => {
            // Global site tag (gtag.js) - Google Analytics
            const script = document.createElement("script")
            script.async = true
            script.src =
                "https://www.googletagmanager.com/gtag/js?id=G-R33P802LM3"
            script.onload = () => {
                window.gtag("consent", "update", {
                    ad_storage: "granted",
                    analytics_storage: "granted",
                    functionality_storage: "granted",
                    personalization_storage: "granted",
                    security_storage: "granted",
                })
            }
            document.body.appendChild(script)
        }
        return <Component {...props} onClick={onClick} />
    }
}
